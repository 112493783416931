import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page.css';
import WalletCard from '../web3Components/WalletCard';
import Button from "react-bootstrap/Button";


const Home = () => {
	// useNav takes in a navLinkId and returns a ref
	// this ref is used to register the navLinkId that's
	// currently in view, and apply activeClass styling
	// to the corresponding nav childElement

	const homeRef = useNav('Whitelist');

	return (
		<section ref={homeRef} id='whitelistContainer'>
			<WalletCard/>
		</section>
		
	);
};

export default Home;
