import React from "react";
import NavLink from "./NavLink";
import { navLinks } from "./navLinks";
import "./Nav.css";
import discord from "../images/discord-logo.ico";
import twitter from "../images/twitter-logo.ico";
import medium from "../images/medium-logo.ico";
import rarity from "../images/rarity-sniper-logo.ico";
import Button from "react-bootstrap/Button"
import logo from "../images/logo.ico"
import WalletCard from "../web3Components/WalletCard.js"
import { useMediaQuery } from "../customHooks/useMediaQuery";

const Nav = () => {
  const bigScreen = useMediaQuery('(min-width: 960px)');

  return (
    <nav>
      {bigScreen && navLinks.map(({ navLinkId, scrollToId }, idx) => (
        <NavLink key={idx} navLinkId={navLinkId} scrollToId={scrollToId} />
      ))}
      <div class="right">
        <a href="https://discord.gg/9VXDeCx6bH" target="_blank">
          <img src={discord} alt="discord"/>
        </a>
        </div>
        <div class="twitter-right">
        <a href="https://twitter.com/cryptodaomonds" target="_blank">
          <img src={twitter} alt="twitter"/>
        </a>
      </div>
      <div class="medium-right">
        <a href="https://medium.com/@CryptoDAOmonds" target="_blank">
          <img src={medium} alt="medium"/>
        </a>
      </div>
      <div class="rarity-right">
        <a href="https://raritysniper.com/" target="_blank">
          <img src={rarity} alt="rarity"/>
        </a>
        </div>
      <div class="wallet-right">
      <WalletCard isNavBar={true}></WalletCard>
      </div>
    </nav>
  );
};

export default Nav;
