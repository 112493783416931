// https://docs.metamask.io/guide/ethereum-provider.html#using-the-provider

import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import MainImage from "../images/banner.png";
import { useMediaQuery } from "../customHooks/useMediaQuery";
import { useWindowSize } from "rooks";
import ParticleImage, {
  ParticleOptions,
  Vector,
  forces,
  ParticleForce,
} from "react-particle-image";

const WalletCard = ({ isNavBar }) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [connButtonText, setConnButtonText] = useState(
    !isNavBar ? "JOIN THE WHITELIST" : "CONNECT WALLET"
  );
  const [mainMessage, setMainMessage] = useState(
    "\nCRYPTODAOMONDS ARE 11,111 COLLECTIBLE ERC-721 UTILITY NFT \n STORED ON ETHEREUM BLOCKCHAINS \n  PRESALE: 3-22-22"
  );
  const [visitorMessage, setVisitorMessage] = useState(
    null
  );
  const [email, setEmail] = useState("");
  const [mintCount, setMintCount] = useState(0);

  const bigScreen = useMediaQuery("(min-width: 600px)");

  const checkIfWalletIsConnected = async () => {
    if (window.ethereum) {
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      if (accounts.length > 0) {
        console.log(accounts[0]);
        connectWalletHandler(accounts[0]);
        return;
      }

      if (isMobileDevice()) {
        connectWalletHandler();
      }
    }
  };

  useEffect(() => {
    checkIfWalletIsConnected();
  }, []);
  const { innerWidth, innerHeight } = useWindowSize();

  const connectWalletHandler = (account = null) => {
    if (window.ethereum && window.ethereum.isMetaMask) {
      console.log("MetaMask Here!");

      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          console.log(result[0]);
          if (account == null) {
            accountChangedHandler(result[0]);
            setConnButtonText("Wallet Connected");
            getAccountBalance(result[0]);
          } else {
            accountChangedHandler(account);
            setConnButtonText("Wallet Connected");
            getAccountBalance(account);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      console.log("Need to install MetaMask");
      setErrorMessage("Please install MetaMask browser extension to interact");
    }
  };

  // update account, will cause component re-render
  const accountChangedHandler = (newAccount) => {
    setDefaultAccount(newAccount);
    getAccountBalance(newAccount.toString());
  };

  const getAccountBalance = (account) => {
    window.ethereum
      .request({ method: "eth_getBalance", params: [account, "latest"] })
      .then((balance) => {
        saveWhitelist(account, ethers.utils.formatEther(balance));
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });
  };

  const chainChangedHandler = () => {
    // reload the page to avoid any errors with chain change mid use of application
    window.location.reload();
  };

  if (window.ethereum) {
    // listen for account changes
    window.ethereum.on("accountsChanged", accountChangedHandler);

    window.ethereum.on("chainChanged", chainChangedHandler);
  }

  const saveWhitelist = (wallet_address, balance) => {
    axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
    axios
      .post(
        "https://pgo5q3b3f9.execute-api.us-east-1.amazonaws.com/prod/whitelist",
        {
          wallet_address: wallet_address,
          balance: balance,
          email_address: email,
        }
      )
      .then(function (response) {
        if (response.data.body.segment.S === "2")
          setVisitorMessage(
            "\nYOU ARE IN THE DIAMOND ELITE PLATINUM+ CLUB\n YOUR MINT LIMIT IS 11 DIAMONDS"
          );  
        else if (response.data.body.segment.S === "1")
          setVisitorMessage(
            "\nYOU ARE IN THE ELITE PLATINUM CLUB\n YOUR MINT LIMIT IS 5 DIAMONDS"
          );
        else if (response.data.body.segment.S === "0") {
          setVisitorMessage(
            "\nYOUR WALLET IS IN THE WHITELIST \n  YOUR MINT LIMIT IS 2 DIAMONDS"
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const contract = {
    abi: "",
  };
  const contractAddress = "address";
  const abi = contract.abi;

  const mintNftHandler = async () => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();

        //   const nftContract = new ethers.Contract(contractAddress, abi, signer);

        console.log("Initialize payment");

        axios.defaults.headers.post["Content-Type"] =
          "application/x-www-form-urlencoded";
        axios
          .post(
            "https://pgo5q3b3f9.execute-api.us-east-1.amazonaws.com/prod/mint",
            {
              count: Number(mintCount),
              address: "0x1d2eaef17b60c60be3e97ec0ff05390168c4c498",
            }
          )
          .then(async function (response) {
            //let nftTxn = await nftContract.serverMint(toAddress, arrayOfRarity, arrayOfTokenIDs);
            console.log("Mining... please wait");
            console.log(typeof response.data.tokenIds)
            if(response.data.tokenIds){
              setVisitorMessage(`Minting please wait...`);
            }
            else if (!response.data.toString().toLowerCase().includes("error")) {
              setVisitorMessage(response.data);
            }
            else{
              setVisitorMessage("Ooops, error occured while processing your mint");
            }
            //await nftTxn.wait();
            //console.log(`Mined, see transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        console.log("Ethereum object does not exist");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateInputValue = (event) => {
    setEmail(event.target.value);
  };

  const updateMintCount = (event) => {
    setMintCount(event.target.value);
  };

  const isMobileDevice = () => {
    return "ontouchstart" in window || "onmsgesturechange" in window;
  };

  const dappUrl = "cryptodaomonds.com";
  const metamaskAppDeepLink = "https://metamask.app.link/dapp/" + dappUrl;

  const buttonStyle = bigScreen
    ? { padding: "1rem 2.4rem", "font-size": "2rem", width: "100%" }
    : { padding: "0.5rem 1.2rem", "font-size": "1rem", width: "100%" };

  if (isNavBar) {
    return !isMobileDevice() ? (
      <Button variant="primary" onClick={connectWalletHandler}>
        {connButtonText}
      </Button>
    ) : (
      <a href={metamaskAppDeepLink}>
        <Button variant="primary">{connButtonText}</Button>{" "}
      </a>
    );
  }

  const round = (n, step = 100) => Math.ceil(n / step) * step;

  const STEP = 30;

  const particleOptions = {
    filter: ({ x, y, image }) => {
      const pixel = image.get(x, y);
      // Make a particle for this pixel if magnitude < 200 (range 0-255)
      const magnitude = (pixel.r + pixel.g + pixel.b) / 3;
      return magnitude < 250;
    },
    color: ({ x, y, image }) => {
      const pixel = image.get(x, y);
      return `rgba(
		${round(pixel.r, STEP)}, 
		${round(pixel.g, STEP)}, 
		${round(pixel.b, STEP)}, 
		${round(pixel.a, STEP) / 255}
	  )`;
    },
    radius: ({ x, y, image }) => {
      const pixel = image.get(x, y);
      const magnitude = (pixel.r + pixel.g + pixel.b) / 3;
      // Lighter colors will have smaller radius
      return 8;
    },
    mass: () => 40,
    friction: () => 0.07,
    initialPosition: ({ canvasDimensions }) => {
      return new Vector(
        canvasDimensions.width / 2,
        canvasDimensions.height / 2
      );
    },
  };
  const motionForce = (x, y) => {
    return forces.disturbance(x, y, 7);
  };

  return (
    <div style={{ color: "White" }} className="walletCard">
      <Col md="9">
        {!isMobileDevice() ? (
          // <ParticleImage
          //   src={MainImage}
          //   scale={bigScreen ? 0.25 : 0.15}
          //   entropy={1}
          //   width={(innerWidth * 3) / 4}
          //   height={bigScreen ? (innerHeight * 3) / 4 : (innerHeight * 3) / 6}
          //   maxParticles={20000}
          //   particleOptions={particleOptions}
          //   mouseMoveForce={motionForce}
          //   touchMoveForce={motionForce}
          //   backgroundColor="black"
          // />
          <img
            style={{ height: "100%", width: "100%" }}
            src={MainImage}
            alt="roadmap-img"
          />
        ) : (
          <img
            style={{ height: "100%", width: "100%" }}
            src={MainImage}
            alt="roadmap-img"
          />
        )}
      </Col>
      <div className="accountDisplay">
        <h2
          style={{
            "padding-bottom": "1em",
            "white-space": "pre-line",
            color: "White",
            "text-align": "center",
          }}
        >
          {mainMessage}
        </h2>
      </div>
      <Col md="4">
        {!isMobileDevice() ? (
          <Button
            style={buttonStyle}
            variant="primary"
            onClick={() => {
              window.open("https://discord.gg/9VXDeCx6bH");
            }}
          >
            {!visitorMessage ? "JOIN THE WHITELIST" : "GO TO DISCORD"}
          </Button>
        ) : (
          <Button
            style={buttonStyle}
            variant="primary"
            onClick={() => {
              window.open("https://discord.gg/9VXDeCx6bH");
            }}
          >
            {!visitorMessage ? "JOIN THE WHITELIST" : "GO TO DISCORD"}
          </Button>
        )}
        {/* <div style={{ display: "inline-flex" }}>
          <Button
            style={buttonStyle}
            variant="danger"
            onClick={() =>
              mintCount
                ? setMintCount(mintCount - 1)
                : setVisitorMessage("Mint amount can't be negative :)")
            }
          >
            {"-"}
          </Button>
          <Button
            style={
              bigScreen
                ? {
                    padding: "1rem 2.4rem",
                    "font-size": "2rem",
                    width: "100%",
                    margin: "1em 1em",
                  }
                : {
                    padding: "0.5rem 1.2rem",
                    "font-size": "1rem",
                    width: "100%",
                    margin: "1em 1em",
                  }
            }
            variant="primary"
            onClick={mintNftHandler}
            disabled={!mintCount}
          >
            {`MINT ${mintCount} ${mintCount > 1 ? "DIAMONDS" : "DIAMOND"}`}
          </Button>
          <Button
            style={buttonStyle}
            variant="success"
            onClick={() => setMintCount(mintCount + 1)}
          >
            {"+"}
          </Button>
        </div> */}
      </Col>
      <div className="accountDisplay">
        <h2
          style={{
            "padding-bottom": "1em",
            "white-space": "pre-line",
            color: "White",
            "text-align": "center",
          }}
        >
          {visitorMessage}
        </h2>
      </div>
    </div>
  );
};

export default WalletCard;
