import React from "react";
import "./Page.css";

const Footer = () => {

  return (
    <footer class="site-footer">
      <div class="container">
        <div class="row">
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-6 col-xs-12">
            <p class="copyright-text">Copyright &copy; 2022 All Rights Reserved by 
         <a href="#"> CryptoDAOmonds</a>.
            </p>
          </div>
        </div>
      </div>
</footer>
  );
};

export default Footer;
