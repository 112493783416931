import React, { useState } from "react";
import { useNav } from "../customHooks/useNav";
import "./Page.css";
import {Accordion, Col} from "react-bootstrap";
import { useMediaQuery } from "../customHooks/useMediaQuery";

const FaqPage = () => {
  const faqRef = useNav('FaqPage');
  const bigScreen = useMediaQuery('(min-width: 600px)');

  const headerStyle = {
    backgroundColor: '#000000', color:"white", display:"block"
  }

  const textStyle = {
    backgroundColor: '#000000', color:"white"
  }
  
  return (
    <section ref={faqRef} id="faqContainer">
      <Col md="6" style={{ "padding-bottom": "3em"}}>
      <h4 style={{"white-space": "pre-wrap",  color: "#c48a00", "text-align": "center"}}>{"FAQ"}</h4>
      <Accordion style={{headerStyle}} flush>
        <Accordion.Item style={headerStyle} eventKey="0">
          <Accordion.Header style={textStyle}>What is DAO?</Accordion.Header>
          <Accordion.Body style={textStyle}>
          Known as a decentralized autonomous organization, DAO refers to entities governed by computer code and contains transparent rules. The entity is controlled by the members and independent from the central government. In simple terms, this means CryptoDAOmonds is controlled by its members and is entirely transparent and inclusive. We are separate from any central governing bodies. This means you have full ownership and control of your NFTs with minimal risk. We allow beginners to get involved with the world of NFT by enabling you to buy into a larger share of NFTs. Check out our roadmap for more information. 
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item style={headerStyle} eventKey="1">
          <Accordion.Header style={textStyle}>What is $WOW?</Accordion.Header>
          <Accordion.Body style={textStyle}>
          $WOW is the token that we have created for CryptoDOAmonds. You receive $WOW when you stake your diamonds with us. Your NFT is able to earn throughout the day. The more NFTs you stake and the longer your hold for, the more $WOW you’ll earn.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item style={headerStyle} eventKey="2">
          <Accordion.Header style={textStyle}>What perks do I get as a member? </Accordion.Header>
          <Accordion.Body style={textStyle}>
          At CryptoDAOmonds, we value our community. We have a generous rewards program that allows you to have exclusive access to merchandise, giveaways, voting and other perks. 
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item style={headerStyle} eventKey="3">
          <Accordion.Header style={textStyle}>How do I earn rewards?</Accordion.Header>
          <Accordion.Body style={textStyle}>You earn rewards by holding onto your CryptoDAOmonds. Your diamond characters can be staked to earn $WOW, which will be used to get rewards. As a community, we host plenty of events with our members, and this provides you with a wealth of opportunities to win some awesome prizes and get involved.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item style={headerStyle} eventKey="4">
          <Accordion.Header style={textStyle}>How do I get started?</Accordion.Header>
          <Accordion.Body style={textStyle}>
          To get started with CryptoDAOmonds, sign-up for our newsletter updates. You should also follow us on twitter to ensure you don’t miss out on our launch date. Make sure your crypto wallet is linked to our website prior to the mint date.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      </Col>
      {bigScreen && <h5 style={{backgroundColor: '#000000', color:"white", 'text-align':"center"}}>If you are interested in becoming a partner or sponsor, please feel free to reach us out at 
        <a href="mailto:mailto:info@cryptodaomonds.com" style={{"color":"#c48a00"}}> info@cryptodaomonds.com</a>
         </h5>}
    </section>

  );
};

export default FaqPage;
