import React from "react";
import "./FlipCard.scss";

const isMobileDevice = () => {
    return "ontouchstart" in window || "onmsgesturechange" in window;
  };

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flipped: false,
      clicked: false,
    };
  }

  flip = () => {
    this.setState({
      flipped: !this.state.flipped,
      clicked: true,
    });
  };

  render() {
    var flippedCSS = this.state.flipped
      ? " Card-Back-Flip"
      : " Card-Front-Flip";
    if (!this.state.clicked) flippedCSS = "";
    return (
      <div className="Card" onMouseEnter={this.flip} onMouseLeave={this.flip}>
        <div className={"Card-Front" + flippedCSS}>
          <img
            style={{ height: "100%", width: "100%" }}
            src={this.props.image}
            alt="diamond"
          />
        </div>
        <div className={"Card-Back" + flippedCSS}>{this.props.children}</div>
      </div>
    );
  }
}
export default Card;
