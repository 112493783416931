import React from "react";
import { useNav } from "../customHooks/useNav";
import "./Page.css";
import { Col } from "react-bootstrap";
import CardContainer from "./Cards";
import Card from "./FlipCard";
import SuperbDiamond from "../images/superb.png";
import RareDiamond from "../images/rare.png";
import ScarceDiamond from "../images/scarce.png";
import LimitedDiamond from "../images/limited.png";
import StandardDiamond from "../images/standard.png";

const Story = () => {
  const aboutRef = useNav("Story");

  const Highlight = ({ text }) => {
    return (
      <h4 style={{ "white-space": "pre-wrap", color: "White" }}>{text}</h4>
    );
  };
  return (
    <section style={{ padding: 0 }} ref={aboutRef} id="storyContainer">
      <Col md={8}>
        <h3 style={{ "white-space": "pre-wrap", color: "#c48a00" }}>
          {"CRYPTODAOMONDS"}
        </h3>
        <h4 style={{ "white-space": "pre-wrap", color: "White" }}>
          {`Earn big and strike riches with CryptoDAOmonds. 11,111 unique items will be minted on the mint date, allowing you to begin amassing your collection of diamond characters.
        `}
        </h4>
        <h4 style={{ "white-space": "pre-wrap", color: "White" }}>
          {`Once 100% sellout is reached, 11 of the Legendary diamond characters will be auctioned on a secondary market. Collect your diamond characters today and get ready to become a wealthy diamond owner.
    `}
        </h4>

        <div className="Cards">
          <Card image={SuperbDiamond}>
            <h4
              style={{ "white-space": "pre-wrap", color: "#ffffff" }}
              className="vertical-timeline-element-subtitle"
            >
              {`\nThe Superb Diamonds`}
            </h4>
            <p style={{ "margin-bottom": "1rem", color: "#ffffff" }}>
              The rarest diaomonds of our NFT collection. They earn you 11 $WOW
              token daily if you stake them.
            </p>
            <p
              style={{
                "white-space": "pre-wrap",
                "margin-bottom": "1rem",
                color: "#ffffff",
              }}
            >
              {`=> Clarity = 11 (MAXIMUM)\n=> Carbon = 99.99% (MAXIMUM)\n=> Carat = 90 to 100`}
            </p>
          </Card>
          <Card image={RareDiamond}>
            <h4
              style={{  "white-space": "pre-wrap", color: "#ffffff" }}
              className="vertical-timeline-element-subtitle"
            >
              {`\nThe Rare Diamonds`}
            </h4>
            <p style={{ "margin-bottom": "1rem", color: "#ffffff" }}>
              These diamonds are rare to be found as you can tell from their
              name :) Their yield generation is 8 $WOW a day.
            </p>
            <p
              style={{
                "white-space": "pre-wrap",
                "margin-bottom": "1rem",
                color: "#ffffff",
              }}
            >
              {`=> Clarity = 9 or 10\n=> Carbon = 99.99%(MAXIMUM)\n=> Carat = 66 to 99`}
            </p>
          </Card>
          <Card image={ScarceDiamond}>
            <h4
              style={{ "white-space": "pre-wrap", color: "#000000" }}
              className="vertical-timeline-element-subtitle"
            >
              {`\nThe Scarce Diamonds`}
            </h4>
            <p style={{ "margin-bottom": "1rem", color: "#000000" }}>
              There are 2000 of the scarce diamonds and their yield generation
              from staking is 5 $WOW token a day.
            </p>
            <p
              style={{
                "white-space": "pre-wrap",
                "margin-bottom": "1rem",
                color: "#000000",
              }}
            >
              {`=> Clarity = 8 or 10\n=> Carbon = 99.97%\n=> Carat = 50 to 65`}
            </p>
          </Card>
          <Card image={LimitedDiamond}>
            <h4
              style={{"white-space": "pre-wrap", color: "#000000" }}
              className="vertical-timeline-element-subtitle"
            >
              {`\nThe Limited Diamonds`}
            </h4>
            <p style={{ "margin-bottom": "1rem", color: "#000000" }}>
              There are 2000 of the limited diamonds and their yield generation
              when they are staked is 3 $WOW token a day.
            </p>
            <p
              style={{
                "white-space": "pre-wrap",
                "margin-bottom": "1rem",
                color: "#000000",
              }}
            >
              {`=> Clarity = 5 or 7\n=> Carbon = 99.97%\n=> Carat = 30 to 49`}
            </p>
          </Card>
          <Card image={StandardDiamond}>
            <h4
              style={{"white-space": "pre-wrap", color: "#ffffff" }}
              className="vertical-timeline-element-subtitle"
            >
              {`\nThe Standard Diamonds`}
            </h4>
            <p style={{ "margin-bottom": "1rem", color: "#ffffff" }}>
              There are over 5000 of these waiting to be minted by their future
              owners. Each one gives 2 $WOW daily when staked.
            </p>
            <p
              style={{
                "white-space": "pre-wrap",
                "margin-bottom": "1rem",
                color: "#ffffff",
              }}
            >
              {`=> Clarity = 1 or 4\n=> Carbon = 99.95%\n=> Carat = 1 to 29`}
            </p>
          </Card>
        </div>

        <h3 style={{ "white-space": "pre-wrap", color: "#c48a00" }}>
          {"TOKENOMICS"}
        </h3>
        <h4 style={{ "white-space": "pre-wrap", color: "White" }}>
          {`Each diamond character you own can be staked to earn $WOW token, and the rarer your characters are, the more $WOW token you are going to recieve!
    `}
        </h4>
        <h4 style={{ "white-space": "pre-wrap", color: "White" }}>
          {`Every $WOW token holding allows its owners to vote on the project development. Anything from new releases to experiences can be decided upon by voters and paid by the community wallet. With your diamonds, you’ll also have access to epic rewards, including merch, NFT claims, raffles, community giveaways and meet-up events.
    `}
        </h4>
      </Col>
    </section>
  );
};

export default Story;
