import React from "react";
import { useNav } from "../customHooks/useNav";
import { useMediaQuery } from "../customHooks/useMediaQuery";
import "./Page.css";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { Col } from "react-bootstrap";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SUPERBGIF from "../images/SUPERBGIF.gif";
import MONEY from "../images/MONEY.gif";
import MININGGIF from "../images/games-gif.gif";
import NFTSTOBUY from "../images/nfts-to-buy.gif";
import WOWTOKEN from "../images/WOWTOKEN.gif";
import Diamond1 from "../images/diamondNoBack.png";
import Diamond2 from "../images/diamondNoBack2.png";
import Diamond3 from "../images/diamondNoBack3.png";
import Diamond4 from "../images/diamondNoBack4.png";
import Diamond5 from "../images/diamondNoBack5.png";

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 120,
  strokeWidth: 6,
};

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time) => (time / daySeconds) | 0;

function toTimestamp(strDate){
  var datum = Date.parse(strDate);
  return datum/1000;
}
const Roadmap = () => {
  const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
  const endTime = toTimestamp('3/22/2022 12:00:00'); // use UNIX timestamp in seconds

  const remainingTime = endTime - stratTime;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  const roadmapRef = useNav("Roadmap");
  const bigScreen = useMediaQuery("(min-width: 960px)");

  return (
    <section ref={roadmapRef} id="roadmapContainer">
      <Col md={8}>
      <div className="timer">
        <CountdownCircleTimer
          {...timerProps}
          colors="#ffffff"
          duration={daysDuration}
          initialRemainingTime={remainingTime}
        >
          {({ elapsedTime, color }) => (
            <span style={{ color }}>
              {renderTime("days", getTimeDays(daysDuration - elapsedTime))}
            </span>
          )}
        </CountdownCircleTimer>
        <CountdownCircleTimer
          {...timerProps}
          colors="#bdc696"
          duration={daySeconds}
          initialRemainingTime={remainingTime % daySeconds}
          onComplete={(totalElapsedTime) => ({
            shouldRepeat: remainingTime - totalElapsedTime > hourSeconds,
          })}
        >
          {({ elapsedTime, color }) => (
            <span style={{ color }}>
              {renderTime("hours", getTimeHours(daySeconds - elapsedTime))}
            </span>
          )}
        </CountdownCircleTimer>
        <CountdownCircleTimer
          {...timerProps}
          colors="#ffe6c7"
          duration={hourSeconds}
          initialRemainingTime={remainingTime % hourSeconds}
          onComplete={(totalElapsedTime) => ({
            shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds,
          })}
        >
          {({ elapsedTime, color }) => (
            <span style={{ color }}>
              {renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))}
            </span>
          )}
        </CountdownCircleTimer>
        <CountdownCircleTimer
          {...timerProps}
          colors="#94edfd"
          duration={minuteSeconds}
          initialRemainingTime={remainingTime % minuteSeconds}
          onComplete={(totalElapsedTime) => ({
            shouldRepeat: remainingTime - totalElapsedTime > 0,
          })}
        >
          {({ elapsedTime, color }) => (
            <span style={{ color }}>
              {renderTime("seconds", getTimeSeconds(elapsedTime))}
            </span>
          )}
        </CountdownCircleTimer>
        
      </div>
      </Col>
      <VerticalTimeline>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(9, 15, 40)", color: "#ffffff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(9, 15, 40)" }}
          date="03-22-22"
          iconStyle={{ background: "rgb(9, 15, 40)" }}
          icon={
            <img
              style={{ height: "100%", width: "100%" }}
              src={Diamond5}
              alt="diamond"
            />
          }
        >
          <h3
            style={{ color: "#ffffff"}}
            className="vertical-timeline-element-title"
          >
            PRE-SALE WHITELIST LAUNCH
          </h3>
          <p style={{ "margin-bottom": "1rem", color: "#ffffff" }}>
            We are going to do our initial launch on this day. Only whitelist
            members are going to be eligible to mint for 0.11 $ETH. First 500 NFTs are going to be FREE!
          </p>
          <h4
            style={{ color: "#ffffff" , "text-align": "center" }}
            className="vertical-timeline-element-subtitle"
          >
            General Whitelist Members
          </h4>
          <p style={{ "margin-bottom": "1rem", color: "#ffffff" }}>
            They can mint up to 2 Diamonds.
          </p>
          <h4
            style={{ color: "#ffffff" , "text-align": "center" }}
            className="vertical-timeline-element-subtitle"
          >
            Elite Platinum Members 
          </h4>
          <h4
            style={{ color: "#ffffff" , "text-align": "center" }}
            className="vertical-timeline-element-subtitle"
          >
            (35% SOLD OUT)
          </h4>
          <p style={{ "margin-bottom": "1rem", color: "#ffffff" }}>
            They can mint up to 5 Diamonds that will be mix of superb, rare or
            scarce diamonds.
          </p>
          <h4
            style={{ color: "#ffffff" , "text-align": "center" }}
            className="vertical-timeline-element-subtitle"
          >
            Diamond Elite Platinum+ Members (100% SOLD OUT)
          </h4>
          <p style={{ "margin-bottom": "1rem", color: "#ffffff" }}>
            They can mint up to 11 Diamonds that will be either superb or rare.
          </p>

          <img
            style={{ height: "100%", width: "100%" }}
            src={SUPERBGIF}
            alt="diamond"
          />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(148, 237, 253)", color: "#ffffff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(148, 237, 253)" }}
          date="TBA"
          iconStyle={{ background: "rgb(148, 237, 253)" }}
          icon={
            <img
              style={{ height: "100%", width: "100%" }}
              src={Diamond4}
              alt="diamond"
            />
          }
        >
          <h3
            style={{ color: "#000000" }}
            className="vertical-timeline-element-title"
          >
            PUBLIC MINT
          </h3>
          <p style={{ "margin-bottom": "1rem", color: "#000000" }}>
            We are opening our NFTs to everyone. Public mint is going to be
            available on our website. The public mint price is 0.22 $ETH.  
          </p>
          <h4
            style={{ color: "#000000" , "text-align": "center" }}
            className="vertical-timeline-element-subtitle"
          >
            General Members
          </h4>
          <p style={{ "margin-bottom": "1rem", color: "#000000" }}>
            They can only mint a single NFT.
          </p>
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(106, 1, 147)", color: "#ffffff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(106, 1, 147)" }}
          date="Soon after the public mint date"
          iconStyle={{ background: "rgb(106, 1, 147)" }}
          icon={
            <img
              style={{ height: "100%", width: "100%" }}
              src={Diamond3}
              alt="diamond"
            />
          }
        >
          <h3 className="vertical-timeline-element-title">$WOW TOKEN</h3>
          <p style={{ "margin-bottom": "1rem", color: "#ffffff" }}>
            Once the minted NFTs are revealed, diamond NFT owners is going to
            start earning $WOW token. The tokens are generated based on the
            daily yield amount of the NFTs staked by their owners.
          </p>
          <h4 className="vertical-timeline-element-subtitle"  style={{"text-align": "center" }}>
            Token Generation
          </h4>
          <p style={{ "margin-bottom": "1rem", color: "#ffffff" }}>
            There are 5 different types of diamond NFTs and each can generate
            different number of tokens, ranging from 2 to 11 $WOW token daily.
          </p>
          <Col md={6}>
          <img
            style={{ height: "100%", width: "100%" }}
            src={WOWTOKEN}
            alt="diamond"
          />
          </Col>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(189, 198, 150)", color: "#ffffff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(189, 198, 150)" }}
          date="After the public mint date"
          iconStyle={{ background: "rgb(189, 198, 150)" }}
          icon={
            <img
              style={{ height: "100%", width: "100%" }}
              src={Diamond1}
              alt="diamond"
            />
          }
        >
          <h3
            style={{ color: "#000000" }}
            className="vertical-timeline-element-title"
          >
            SELL OUT MILESTONES
          </h3>
          <p style={{ "margin-bottom": "1rem", color: "#000000" }}>
            We are going to buy blue chip NFTs for our community after a
            sell-out milestone achieved.
          </p>
          <h4
            style={{ color: "#000000" , "text-align": "center" }}
            className="vertical-timeline-element-subtitle"
          >
            50% SELL-OUT
          </h4>
          <p style={{ "margin-bottom": "1rem", color: "#000000" }}>
            First BLUECHIP NFT purchase
          </p>
          <h4
            style={{ color: "#000000", "text-align": "center"  }}
            className="vertical-timeline-element-subtitle"
          >
            100% SELL-OUT
          </h4>
          <p style={{ "margin-bottom": "1rem", color: "#000000" }}>
            35 NFT purchase including BLUECHIP NFTs and NFTs with staking feature
          </p>
          <img
            style={{ height: "100%", width: "100%" }}
            src={NFTSTOBUY}
            alt="diamond"
          />
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(96 125 139)", color: "#ffffff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(96 125 139)" }}
          date="2022-2023"
          iconStyle={{ background: "rgb(96 125 139)" }}
          icon={
            <img
              style={{ height: "100%", width: "100%" }}
              src={Diamond2}
              alt="diamond"
            />
          }
        >
          <h3
            style={{ color: "#FFFFFF" }}
            className="vertical-timeline-element-title"
          >
            VENTURES
          </h3>
          <p style={{ "margin-bottom": "1rem", color: "#FFFFFF" }}>
            We are going to have new ventures with cryptoDAOmond community's
            vote and contribution.
          </p>
          <h4
            style={{ color: "#FFFFFF", "text-align": "center"  }}
            className="vertical-timeline-element-subtitle"
          >
            GAMES
          </h4>
          <p style={{ "margin-bottom": "1rem", color: "#FFFFFF" }}>
            Our diamonds are designed to be collected and used in various games.
            We will explore the opportunities in different metaverse platforms
            as well as start developing our own games.
          </p>
          <img
            style={{ height: "100%", width: "100%" }}
            src={MININGGIF}
            alt="diamond"
          />
        </VerticalTimelineElement>
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "rgb(204, 91, 0)", color: "#ffffff" }}
          contentArrowStyle={{ borderRight: "7px solid  rgb(204, 91, 0)" }}
          date="2022-2023"
          iconStyle={{ background: "rgb(204, 91, 0)" }}
          icon={
            <img
              style={{ height: "100%", width: "100%" }}
              src={Diamond1}
              alt="diamond"
            />
          }
        >
          <h3
            style={{ color: "#ffffff" }}
            className="vertical-timeline-element-title"
          >
            REWARDS
          </h3>
          <p style={{ "margin-bottom": "1rem", color: "#ffffff" }}>
            Once we reach to enough $WOW token supply from the yield
            generations, we are going to provide liquidity to our token. That
            means the token you are holding is going to have a value against
            $ETH and have option to exchange your tokens with $ETH if you want
            to.
          </p>
          <Col md={4}>
          <img
            style={{ height: "100%", width: "100%" }}
            src={MONEY}
            alt="diamond"
          />
          </Col>
        </VerticalTimelineElement>
      </VerticalTimeline>
      )
    </section>
  );
};

export default Roadmap;
