import React from 'react';
import { Home, Roadmap, About, Story, Faq, Footer } from './';
import { useMediaQuery } from "../customHooks/useMediaQuery";

const Main = () => 
{  const bigScreen = useMediaQuery('(min-width: 500px)');
	return(
	
	<main>
		<Home />
		<Roadmap />
		<Story />
		<Faq />
		{bigScreen && <Footer/>}
	</main>
);
	}

export default Main;
